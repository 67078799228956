<by-multi-select
  *ngIf="columns && columns.length"
  [byClass]="byClass"
  [nzAllowClear]="allowClear"
  [options]="columns"
  [(ngModel)]="selectedColumns"
  (ngModelChange)="onSelectedColumnsChange()"
  [noEmpty]="true"
  [showIconEye]="true"
  [defaultData]="defaultColumns"
  label="name"
  value="id"
></by-multi-select>
