import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ColumnDirective } from './column.directive';
import { ContainerCellDirective } from './container-cell.directive';
import { HeaderDirective } from './header.directive';

@Injectable()
export class TableWrapperService {
  extraColumns = new BehaviorSubject<ColumnDirective[]>([]);

  extraHeaders$ = new BehaviorSubject<HeaderDirective[]>([]);

  containerCells$ = new BehaviorSubject<ContainerCellDirective[]>([]);

  addColumns(columns: ColumnDirective[]) {
    this.extraColumns.next([...columns, ...this.extraColumns.value]);
  }

  addHeaders(headers: HeaderDirective[]) {
    this.extraHeaders$.next([...headers, ...this.extraHeaders$.value]);
  }

  addContainerCells(containerCells: ContainerCellDirective[]) {
    this.containerCells$.next([
      ...containerCells,
      ...this.containerCells$.value,
    ]);
  }
}
