import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[byTable]',
})
export class TableDirective implements AfterViewInit {
  constructor(private renderer: Renderer2, private element: ElementRef) {
    this.renderer.addClass(this.element.nativeElement, 'generic-list-table');
  }

  ngAfterViewInit() {}
}
