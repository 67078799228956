import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[byHeader]',
})
export class HeaderDirective {
  @Input() preferenceKey: string = null;

  constructor(public elementRef: ElementRef) {}
}
