import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[byColumn]',
})
export class ColumnDirective {
  @Input() preferenceKey: string = null;

  @Input() headerSelector: string = null;

  @Input() default = true;

  @Input() label: string = null;

  @Input() removeFullHeader = false;

  @Input() order = 0;

  constructor(public elementRef: ElementRef) {}
}
