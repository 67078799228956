import { createFeatureSelector, createSelector } from '@ngrx/store';

import { featureAdapter, State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getPagination = (state: State) => state.pagination;

export const getEntities = (state: State) => state.entities;

export const getDownloadingIds = (state: State) => state.downloadingIds;

export const selectState = createFeatureSelector<State>('exports');

export const selectAllItems =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectItemById = (id: number) =>
  createSelector(
    featureAdapter.getSelectors(selectState).selectEntities,
    (entities) => entities[id],
  );

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectPagination = createSelector(selectState, getPagination);

export const selectDownloadingIds = createSelector(
  selectState,
  getDownloadingIds,
);
