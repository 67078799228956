import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ExportWidgetData, PrintOrientation } from '../../../models';

@Component({
  selector: 'by-table-print-widget',
  templateUrl: './table-print-widget.component.html',
  styleUrls: ['./table-print-widget.component.scss'],
})
export class TablePrintWidgetComponent implements OnInit {
  @Input() columnsToExport: string[] = [];

  @Input() showTooltip = true;

  @Input() loading = false;

  @Input() selectOrientation = false;

  @Output() print = new EventEmitter<ExportWidgetData>();

  constructor() {}

  ngOnInit() {}

  onPrint(orientation?: PrintOrientation) {
    this.print.emit({
      format: 'pdf',
      type: 'print',
      columns: this.columnsToExport,
      orientation,
    });
  }
}
