import {
  AfterContentInit,
  ContentChildren,
  Directive,
  OnDestroy,
  QueryList,
} from '@angular/core';
import { SubSink } from 'subsink';

import { ColumnDirective } from './column.directive';
import { ContainerCellDirective } from './container-cell.directive';
import { TableWrapperService } from './table-wrapper.service';
import { HeaderDirective } from './header.directive';

@Directive({
  selector: '[byTableWrapperConnector]',
})
export class TableWrapperConnectorDirective
  implements AfterContentInit, OnDestroy
{
  @ContentChildren(ColumnDirective, { descendants: true })
  columns: QueryList<ColumnDirective>;

  @ContentChildren(ContainerCellDirective, { descendants: true })
  containerCells: QueryList<ContainerCellDirective>;

  @ContentChildren(HeaderDirective, { descendants: true })
  headers: QueryList<HeaderDirective>;

  private subs = new SubSink();

  constructor(private tableWrapperService: TableWrapperService) {}

  ngAfterContentInit() {
    this.syncServiceAttribute(this.columns, 'addColumns');
    this.syncServiceAttribute(this.headers, 'addHeaders');
    this.syncServiceAttribute(this.containerCells, 'addContainerCells');
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private syncServiceAttribute<T>(
    queryList: QueryList<T>,
    serviceMethodName: keyof Pick<
      TableWrapperService,
      'addColumns' | 'addHeaders' | 'addContainerCells'
    >,
  ) {
    const update = (a) => {
      this.tableWrapperService[serviceMethodName](a);
    };

    update(queryList.toArray());

    this.subs.add(
      queryList.changes.subscribe(() => update(queryList.toArray())),
    );
  }
}
