import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  generateParamArray,
  generateSearchQuery,
} from '@app/core/helpers/params-generator';
import { NewExportRequest } from '../models/requests/exports-request';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import {
  Export,
  ExportsOption,
  ExportsProvider,
  ExportsSearch,
} from '../models/objects/exports';

@Injectable({
  providedIn: 'root',
})
export class ExportsService {
  constructor(private http: HttpClient) {}

  load(property_ids: number[], searchParams: ExportsSearch) {
    const params = { ...searchParams, property_ids };

    return this.http.get<IResponseSuccess<Export[]>>(
      `exports?${generateSearchQuery(params)}`,
    );
  }

  deleteExport(id: number) {
    return this.http.delete<IResponseSuccess>(`exports/${id}`);
  }

  createNewExport(request: NewExportRequest) {
    return this.http.post<IResponseSuccess<Export>>(`exports`, request);
  }

  loadProviderOptions(id: number) {
    return this.http.get<IResponseSuccess<ExportsOption[]>>(
      `exports/providers/${id}/options`,
    );
  }

  loadProviders(properties: number[]) {
    return this.http.get<IResponseSuccess<ExportsProvider[]>>(
      `exports/providers?${generateParamArray('property_ids', properties)}`,
    );
  }

  download(url: string) {
    return this.http.get(url, { responseType: 'blob' });
  }

  checkExportCreation(exportId: number) {
    return this.http.get<IResponseSuccess<Export>>(`exports/${exportId}`);
  }
}
