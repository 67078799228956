import { createAction, props } from '@ngrx/store';
import { Export, ExportsSearch } from '../../models/objects/exports';
import { NewExportRequest } from '../../models/requests/exports-request';
import { IPagination } from '../../core/models/api/pagination/pagination.model';
import { Hooks } from '../../helpers';

export const loadRequest = createAction(
  '[Exports] Load Request',
  props<{
    disableLoading?: boolean;
    property_ids: number[];
    searchParams: ExportsSearch;
  }>(),
);

export const loadSuccess = createAction(
  '[Exports] Load Success',
  props<{ exports: Export[]; pagination: IPagination }>(),
);

export const loadFailure = createAction(
  '[Exports] Load Failure',
  props<{ error: any }>(),
);

export const deleteExportRequest = createAction(
  '[Exports] Delete Export Request',
  props<{
    id: number;
    hooks?: Hooks;
  }>(),
);

export const deleteExportSuccess = createAction(
  '[Exports] Delete Export Success',
  props<{
    id: number;
  }>(),
);

export const deleteExportFailure = createAction(
  '[Exports] Delete Export Failure',
  props<{ error: any }>(),
);

export const createNewExportRequest = createAction(
  '[Exports] Create New Export Request',
  props<{
    exportData: NewExportRequest;
    hooks?: Hooks;
  }>(),
);

export const createNewExportSuccess = createAction(
  '[Exports] Create New Export Success',
  props<{ newExport: Export }>(),
);

export const createNewExportFailure = createAction(
  '[Exports] Create New Export Failure',
  props<{ error: any }>(),
);

export const loadExportOptionsRequest = createAction(
  '[Exports] Load Export Options Request',
  props<{
    providerId: number;
  }>(),
);
export const downloadFileRequest = createAction(
  '[Exports] Download Export File Request',
  props<Export>(),
);

export const downloadFileSuccess = createAction(
  '[Exports] Download Export File Success',
  props<{ downloadingId: number }>(),
);

export const downloadFileError = createAction(
  '[Exports] Download Export File Error',
  props<{ downloadingId: number }>(),
);

export const resetState = createAction('[Exports] Reset State');
