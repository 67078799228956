import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { IPagination } from '../../core/models/api/pagination/pagination.model';

import { Export } from '../../models/objects/exports';

export const featureAdapter: EntityAdapter<Export> =
  createEntityAdapter<Export>({
    selectId: (model) => model.id,
  });

export interface State extends EntityState<Export> {
  pagination: IPagination;
  isLoading?: boolean;
  error?: any;
  downloadingIds: Record<number, boolean>;
}

export const initialState: State = featureAdapter.getInitialState({
  pagination: null,
  isLoading: false,
  error: null,
  downloadingIds: [],
});
