import {
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  HostBinding,
  Input,
  QueryList,
} from '@angular/core';

import { ColumnDirective } from './column.directive';

@Directive({
  selector: '[byContainerCell]',
})
export class ContainerCellDirective {
  @ContentChildren(ColumnDirective, { descendants: true })
  columns: QueryList<ColumnDirective>;

  @Input()
  headerSelector: string = null;

  @Input()
  removeFullHeader = false;

  @HostBinding('style.display')
  display: string;

  constructor(private cdr: ChangeDetectorRef) {}

  get id() {
    return this.preferenceKeys.join('_');
  }

  get preferenceKeys() {
    return this.columns?.map(({ preferenceKey }) => preferenceKey) || [];
  }

  setDisplay(display: string) {
    this.display = display;
    this.cdr.detectChanges();
  }
}
