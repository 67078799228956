import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import * as fromActions from './actions';
import { ExportsService } from '../../services/exports.service';
import { effectHooks } from '../../helpers';
import { FileSaverService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

@Injectable()
export class ExportsStoreEffects {
  constructor(
    private actions$: Actions,
    private dataService: ExportsService,
    private errorHandler: ErrorHandlerService,
    private fileSaverService: FileSaverService,
    private notifications: NotificationService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ property_ids, searchParams }) =>
        this.dataService.load(property_ids, searchParams).pipe(
          map((response) =>
            fromActions.loadSuccess({
              exports: response.data,
              pagination: response.meta.pagination,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  deleteExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteExportRequest),
      switchMap(({ id, hooks }) =>
        this.dataService.deleteExport(id).pipe(
          effectHooks(hooks),
          map(() => {
            this.notifications.deleteSuccess();
            return fromActions.deleteExportSuccess({
              id,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteExportFailure(error));
          }),
        ),
      ),
    ),
  );

  createNewExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createNewExportRequest),
      switchMap(({ exportData, hooks }) =>
        this.dataService.createNewExport(exportData).pipe(
          effectHooks(hooks),
          map((response) => {
            this.notifications.createSuccess();
            return fromActions.createNewExportSuccess({
              newExport: response.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createNewExportFailure(error));
          }),
        ),
      ),
    ),
  );

  download$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.downloadFileRequest),
      mergeMap(({ url, file_name, id }) =>
        this.dataService.download(url).pipe(
          map((blob) => {
            this.fileSaverService.saveAs(blob, file_name);

            return fromActions.downloadFileSuccess({ downloadingId: id });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.downloadFileError({ downloadingId: id }));
          }),
        ),
      ),
    ),
  );
}
