<button
  nz-button
  nzType="primary"
  (click)="selectOrientation ? null : onPrint()"
  class="ant-btn-icon-only"
  nz-tooltip
  [nzTooltipTrigger]="showTooltip && !selectOrientation && 'hover'"
  [nzTooltipTitle]="'print' | translate | upperfirst"
  nz-dropdown
  [nzDropdownMenu]="selectOrientation ? menu : null"
  [nzLoading]="loading"
>
  <i class="fal fa-print" *ngIf="!loading"></i>
</button>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item (click)="onPrint('vertical')">
      <a>{{ 'print_vertical' | translate | upperfirst }}</a>
    </li>
    <li nz-menu-item (click)="onPrint('landscape')">
      <a>{{ 'print_horizontal' | translate | upperfirst }}</a>
    </li>
  </ul>
</nz-dropdown-menu>
