import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { uniq } from 'lodash';

@Component({
  selector: 'by-table-column-widget',
  templateUrl: './table-column-widget.component.html',
  styleUrls: ['./table-column-widget.component.scss'],
})
export class TableColumnWidgetComponent implements OnInit {
  @Input() columns: {
    name: string;
    id: string;
  }[] = [];

  @Input() selectedColumns: string[] = [];

  @Input() defaultColumns: string[] = [];

  @Input() byClass: string;

  @Input() allowClear: boolean = true;

  @Output() selectedColumnsChange = new EventEmitter<string[]>();

  constructor() {}

  ngOnInit() {}

  onSelectedColumnsChange() {
    this.selectedColumnsChange.emit(uniq(this.selectedColumns));
  }
}
