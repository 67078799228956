import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,

  on(fromActions.loadRequest, (state, { disableLoading }) => ({
    ...state,
    isLoading: !disableLoading,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { exports, pagination }) =>
    fromState.featureAdapter.setAll(exports, {
      ...state,
      isLoading: false,
      error: null,
      pagination,
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.deleteExportRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.deleteExportSuccess, (state, { id }) =>
    fromState.featureAdapter.removeOne(id, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.deleteExportFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.createNewExportRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.createNewExportSuccess, (state, { newExport }) =>
    fromState.featureAdapter.addOne(newExport, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.createNewExportFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.loadExportOptionsRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),

  on(fromActions.downloadFileRequest, (state, { id }) => ({
    ...state,
    downloadingIds: {
      ...state.downloadingIds,
      [id]: true,
    },
  })),

  on(fromActions.downloadFileSuccess, (state, { downloadingId }) => ({
    ...state,
    downloadingIds: {
      ...state.downloadingIds,
      [downloadingId]: null,
    },
  })),

  on(fromActions.downloadFileError, (state, { downloadingId }) => ({
    ...state,
    downloadingIds: {
      ...state.downloadingIds,
      [downloadingId]: null,
    },
  })),

  on(fromActions.resetState, () => fromState.initialState),
);

export function exportsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
