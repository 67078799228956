import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgPipesModule } from '@app/shared/ng-pipes/ng-pipes.module';
import { TranslateModule } from '@ngx-translate/core';

import { MultiSelectModule } from '../../ui/multi-select/multi-select.module';
import { UiModule } from '../../ui/ui.module';

import { ColumnDirective } from './column.directive';
import { ContainerCellDirective } from './container-cell.directive';
import { TableColumnWidgetComponent } from './table-column-widget/table-column-widget.component';
import { TableExportWidgetComponent } from './table-export-widget/table-export-widget.component';
import { TablePrintWidgetComponent } from './table-print-widget/table-print-widget.component';
import { TableWrapperConnectorDirective } from './table-wrapper-connector.directive';
import { TableWrapperComponent } from './table-wrapper/table-wrapper.component';
import { TableDirective } from './table.directive';
import { ExportsStoreModule } from '../../root-store/exports-store';

import { HeaderDirective } from './header.directive';

@NgModule({
  declarations: [
    HeaderDirective,
    TableDirective,
    TablePrintWidgetComponent,
    TableColumnWidgetComponent,
    TableWrapperComponent,
    ColumnDirective,
    TableWrapperConnectorDirective,
    ContainerCellDirective,
  ],
  imports: [
    CommonModule,
    UiModule,
    TranslateModule,
    NgPipesModule,
    MultiSelectModule,
    FormsModule,
    ReactiveFormsModule,
    ExportsStoreModule,
    TableExportWidgetComponent,
  ],
  exports: [
    HeaderDirective,
    TableDirective,
    TablePrintWidgetComponent,
    TableColumnWidgetComponent,
    TableWrapperComponent,
    ColumnDirective,
    TableWrapperConnectorDirective,
    ContainerCellDirective,
    TableExportWidgetComponent,
  ],
})
export class TableModule {}
