<div
  nz-tooltip
  [nzTooltipTrigger]="showTooltip && 'hover'"
  [nzTooltipTitle]="tooltipTitle | translate | upperfirst"
>
  <button
    nz-button
    [nzType]="showAsPrintButton ? 'primary' : 'default'"
    class="by-ml-10 ant-btn-icon-only"
    nz-dropdown
    [nzDropdownMenu]="!showAsPrintButton && formats.length > 1 && menu"
    nzTrigger="click"
    [nzLoading]="isLoading"
    (click)="(showAsPrintButton || this.formats.length === 1) && onExportPdf()"
  >
    <i class="fal {{ icon }}" *ngIf="!isLoading"></i>
  </button>

  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
      <li
        *ngIf="formats | firstOrDefault : 'pdf'"
        nz-menu-item
        (click)="onExportPdf()"
      >
        <i class="fal fa-file-pdf"></i> PDF
      </li>
      <li
        *ngIf="formats | firstOrDefault : 'csv'"
        nz-menu-item
        (click)="onExportCsv()"
      >
        <i class="fal fa-file-csv"></i> CSV
      </li>
      <li
        *ngIf="formats | firstOrDefault : 'xlsx'"
        nz-menu-item
        (click)="onExportXlsx()"
      >
        <i class="fal fa-file-spreadsheet"></i> XLSX
      </li>
    </ul>
  </nz-dropdown-menu>
</div>
