import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { first, groupBy, intersection, isEqual, uniqBy } from 'lodash';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { of } from 'rxjs';
import { debounceTime, filter, startWith } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { RootState } from '../../../root-store/root-state';
import {
  UserPreferencesStoreActions,
  UserPreferencesStoreSelectors,
} from '../../../root-store/user-preferences-store';
import { ColumnDirective } from '../column.directive';
import { ContainerCellDirective } from '../container-cell.directive';
import { TableColumnWidgetComponent } from '../table-column-widget/table-column-widget.component';
import { TableExportWidgetComponent } from '../table-export-widget/table-export-widget.component';
import { TablePrintWidgetComponent } from '../table-print-widget/table-print-widget.component';
import { TableWrapperService } from '../table-wrapper.service';
import { HeaderDirective } from '../header.directive';
import { arraysEqual } from '../../../core/helpers';

interface PreferenceOption {
  preferenceKey: string;
  default: boolean;
  label: string;
  order: number;
}

@Component({
  selector: 'by-table-wrapper',
  templateUrl: './table-wrapper.component.html',
  styleUrls: ['./table-wrapper.component.scss'],
})
export class TableWrapperComponent
  implements OnInit, AfterContentInit, OnDestroy, OnChanges
{
  /**
   * Preference key for accessing the store
   */
  @Input() preferenceKey: string = null;

  /**
   * When TRUE EventEmitters and afterContentInitSubscriptions are disabled
   */
  @Input() disableSubscription = false;

  /**
   * Emit list of all the selected options in the select
   */
  @Output() changeSelectedColumns = new EventEmitter<string[]>();

  /**
   * Emit list of all the default columns
   */
  @Output() defaultColumns = new EventEmitter<string[]>();

  /**
   * Emit list of all the preference options (this are the select options)
   */
  @Output() changeColumnsWidget = new EventEmitter<
    Array<{ name: string; id: string }>
  >();

  /**
   * List of all the columns where the directive is attached
   */
  @ContentChildren(ColumnDirective, { descendants: true })
  columns: QueryList<ColumnDirective>;

  /**
   * List of all the headers where the directive is attached
   */
  @ContentChildren(HeaderDirective, { descendants: true })
  headers: QueryList<HeaderDirective>;

  /**
   * List of all container cells where the directive is attached
   */
  @ContentChildren(ContainerCellDirective, { descendants: true })
  containerCells: QueryList<ContainerCellDirective>;

  /**
   * The select for editing the visible columns in the table
   */
  @ContentChild(TableColumnWidgetComponent)
  columnWidget: TableColumnWidgetComponent;

  @ContentChild(TableExportWidgetComponent)
  exportWidget: TableExportWidgetComponent;

  @ContentChild(TablePrintWidgetComponent)
  printWidget: TablePrintWidgetComponent;

  private subs = new SubSink();

  /**
   * List of all the preference options (this are the select options)
   */
  private preferenceOptions = new BehaviorSubject<PreferenceOption[]>([]);

  /**
   * List of all the selected options in the select
   */
  private selectedColumns = new BehaviorSubject<string[]>([]);

  /**
   * List of all columns wrapped
   */
  private columnsChange = new BehaviorSubject<ColumnDirective[]>([]);

  constructor(
    private store: Store<RootState>,
    @Optional() private tableWrapperService: TableWrapperService,
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    const { preferenceKey } = changes;

    if (!preferenceKey || !this.preferenceKey) {
      return;
    }

    this.subs.add(
      combineLatest([
        this.store.pipe(
          select(
            UserPreferencesStoreSelectors.selectUserPreferencesDataByCategory(
              'tablecolumnView',
            ),
          ),
        ),
        this.preferenceOptions,
      ])
        .pipe(
          filter(
            ([preferences, options]: [any[], PreferenceOption[]]) =>
              !!(preferences && options && options.length),
          ),
        )
        .subscribe(([allTablesPreferences, preferenceOptions]) => {
          const tablePreferences =
            allTablesPreferences[this.preferenceKey] || [];

          const selectedColumns = tablePreferences.length
            ? tablePreferences
            : preferenceOptions
                .filter((c) => c.default)
                .map((c) => c.preferenceKey);

          const selectedColumnsChanged = !arraysEqual(
            selectedColumns,
            this.selectedColumns.value,
          );

          this.selectedColumns.next(selectedColumns);

          if (
            !isEqual(tablePreferences, selectedColumns) &&
            tablePreferences.length
          ) {
            this.updatePreferences(selectedColumns);
          }

          this.updateTable(selectedColumns, selectedColumnsChanged);
        }),
    );
  }

  ngAfterContentInit() {
    this.columnsChange.next(this.columns.toArray());

    this.subs.add(
      this.columnsChange.subscribe((columns) => {
        this.preferenceOptions.next(
          uniqBy(
            columns.map((column) => ({
              preferenceKey: column.preferenceKey,
              default: column.default,
              label: column.label || column.preferenceKey,
              order: column.order,
            })),
            'preferenceKey',
          ),
        );
      }),
    );

    if (this.disableSubscription) {
      return;
    }

    this.subs.add(
      this.preferenceOptions.subscribe((columns: PreferenceOption[]) => {
        const cols = columns
          .sort((a, b) => {
            return a.order - b.order;
          })
          .map((c) => ({
            name: c.label,
            id: c.preferenceKey,
          }));
        const defaultColumns = columns
          .filter(({ default: d }) => d)
          .map(({ preferenceKey }) => preferenceKey);

        if (this.columnWidget) {
          this.columnWidget.columns = cols;
          this.columnWidget.defaultColumns = defaultColumns;
        }
        this.defaultColumns.emit(defaultColumns);
        this.changeColumnsWidget.emit(cols);
      }),
    );

    this.subs.add(
      this.selectedColumns.subscribe((selectedColumns: string[]) => {
        if (this.columnWidget) {
          this.columnWidget.selectedColumns = selectedColumns;
        }

        if (this.exportWidget) {
          this.exportWidget.columnsToExport = selectedColumns;
        }

        if (this.printWidget) {
          this.printWidget.columnsToExport = selectedColumns;
        }

        this.changeSelectedColumns.emit(selectedColumns);
      }),
    );

    this.subs.add(
      combineLatest([
        this.columns.changes.pipe(startWith([])),
        this.tableWrapperService?.extraColumns.asObservable() || of([]),
      ]).subscribe(([_, extraColumns]) => {
        this.columnsChange.next([
          ...this.columns.map((c) => c),
          ...extraColumns,
        ]);
      }),
    );

    this.subs.add(
      this.tableWrapperService?.containerCells$.asObservable().subscribe(() => {
        this.columnsChange.next(this.columnsChange.value);
      }),
    );

    if (this.columnWidget) {
      this.subs.add(
        this.columnWidget.selectedColumnsChange
          .pipe(debounceTime(600))
          .subscribe((selectedColumns: string[]) => {
            this.updateTable(selectedColumns, true);
            this.updatePreferences(selectedColumns);
            this.selectedColumns.next(selectedColumns);
          }),
      );
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private updatePreferences(columns: string[]) {
    this.store.dispatch(
      new UserPreferencesStoreActions.UpdateRequestAction({
        preferences: {
          options: {
            tablecolumnView: {
              [this.preferenceKey]: columns,
            },
          },
        },
      }),
    );
  }

  /**
   * TODO: IMPLEMENTARE IL CASO IN CUI ESISTE UN COLSPAN DENTRO I TH
   */
  private updateTable(
    selectedColumns: string[],
    selectedColumnsChanged: boolean,
  ) {
    const extraColumns = this.tableWrapperService?.extraColumns.value || [];
    const extraContainerCells =
      this.tableWrapperService?.containerCells$.value || [];

    /**
     * Mi prendo solo le colonne utili in base alla chiave della preferenza.
     *
     * Questo perché potrei avere 100 righe nella tabella e non ha senso passarle tutte ogni volta, sono sempre uguali.
     */
    const columns = uniqBy(
      [...this.columns.toArray(), ...extraColumns],
      'preferenceKey',
    );

    const groupedContainerCells = groupBy(
      [...this.containerCells.toArray(), ...extraContainerCells],
      'id',
    );

    const allColumns = [...this.columns, ...extraColumns];

    columns.forEach((column) => {
      const oldDisplay = column.elementRef.nativeElement.style.display;

      const newDisplay =
        selectedColumns.indexOf(column.preferenceKey) !== -1 ? '' : 'none';

      const hasChanged = oldDisplay !== newDisplay;

      const checkHeaders = () => {
        const headers = this.getHeaders(column);

        if (!headers.length) {
          return false;
        }

        headers.forEach((header) => {
          if (header.hasAttribute('colspan') && !column.removeFullHeader) {
            if (selectedColumnsChanged && hasChanged) {
              const colspan = +header.getAttribute('colspan');
              const newColspan = newDisplay === '' ? colspan + 1 : colspan - 1;

              header.setAttribute('colspan', `${newColspan}`);

              header.style.display = newColspan === 0 ? 'none' : '';
            }
          } else {
            header.style.display = newDisplay;
          }
        });

        return true;
      };

      if (!checkHeaders()) {
        setTimeout(checkHeaders, 300);
      }
    });

    allColumns.forEach((column) => {
      const oldDisplay = column.elementRef.nativeElement.style.display;

      const newDisplay =
        selectedColumns.indexOf(column.preferenceKey) !== -1 ? '' : 'none';

      column.elementRef.nativeElement.style.display = newDisplay;

      const td: HTMLElement = column.elementRef.nativeElement.closest('td');

      const hasChanged = oldDisplay !== newDisplay;

      if (td && td.hasAttribute('colspan') && hasChanged) {
        const colspan = +td.getAttribute('colspan');
        const newColspan = newDisplay === '' ? colspan + 1 : colspan - 1;

        td.setAttribute('colspan', `${newColspan}`);

        td.style.display = newColspan === 0 ? 'none' : '';
      }
    });

    Object.values(groupedContainerCells).forEach((containerCells) => {
      const firstContainerCell = first(containerCells);

      const atLeastContainerColumnSelected = !!intersection(
        firstContainerCell.preferenceKeys,
        selectedColumns,
      ).length;

      const oldDisplay = firstContainerCell.display;

      const newDisplay = atLeastContainerColumnSelected ? '' : 'none';

      if (oldDisplay === newDisplay) {
        return;
      }

      const check = () => {
        containerCells.forEach((containerCell) => {
          containerCell.setDisplay(newDisplay);
        });

        const headers = document.querySelectorAll<HTMLElement>(
          firstContainerCell.headerSelector,
        );

        headers.forEach((header) => {
          header.style.display = newDisplay;
        });
      };

      check();
      setTimeout(check, 300);
    });
  }

  private getHeaders(column: ColumnDirective): HTMLElement[] {
    const headersDirectives = [
      ...this.headers,
      ...(this.tableWrapperService?.extraHeaders$.value || []),
    ];

    const headers: HTMLElement[] = headersDirectives
      .filter(
        (headerDirective) =>
          headerDirective.preferenceKey === column.preferenceKey,
      )
      .map((headerDirective) => headerDirective.elementRef.nativeElement);

    document
      .querySelectorAll<HTMLElement>(column.headerSelector)
      .forEach((header) => headers.push(header));

    return headers;
  }
}
